var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    attrs: {
      "clearable": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Delegate",
      "items": _vm.items,
      "loading": _vm.loading,
      "item-text": "label",
      "item-value": "xid",
      "value": _vm.value
    },
    on: {
      "change": _vm.valueChanged
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }