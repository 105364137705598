var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('div', {
    attrs: {
      "id": "wallpaper"
    }
  }), _c('transition', {
    attrs: {
      "name": "transition-fade-up"
    }
  }, [_vm.showForm ? _c('v-row', [_c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "11",
      "sm": "8",
      "md": "11",
      "lg": "10",
      "xl": "6"
    }
  }, [_c('v-card', {
    staticClass: "rounded-xl",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-row', {
    staticClass: "text-center d-flex"
  }, [_c('v-col', {
    staticClass: "pa-0 hidden-sm-and-down",
    attrs: {
      "cols": "12",
      "md": "6",
      "id": "rightPane"
    }
  }, [_c('div', {
    attrs: {
      "id": "wallpaper1"
    }
  }), _c('div', {
    attrs: {
      "id": "wallpaper2"
    }
  }), _c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-container', {
    staticClass: "text-center pa-10 titlefont",
    attrs: {
      "id": "text"
    }
  }, [_c('p', {
    staticClass: "ma-0"
  }, [_vm._v("Your gateway to prescribing, manufacturing and dispensing industry leading orthoses.")]), _c('p', {
    staticClass: "ma-0 text-subtitle-1"
  })])], 1)], 1), _c('v-col', {
    staticClass: "pa-8 pa-lg-16",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-container', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "src": require("@/assets/logo.svg")
    }
  }), _vm.mode == 'login' ? _c('p', {
    staticClass: "text-body-2 text--secondary my-10"
  }, [_vm._v("Sign in to get started!")]) : _c('p', {
    staticClass: "text-body-2 text--secondary my-10"
  }, [_vm._v("Let's reset that for you!")]), _c('v-form', {
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Username",
      "outlined": "",
      "rounded": "",
      "color": "primary",
      "autofocus": ""
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _vm.mode == 'login' ? _c('v-text-field', {
    ref: "password",
    attrs: {
      "label": "Password",
      "outlined": "",
      "rounded": "",
      "color": "primary",
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }) : _vm._e(), _c('v-btn', {
    staticClass: "primary white--text",
    attrs: {
      "type": "submit",
      "rounded": "",
      "large": "",
      "block": "",
      "loading": _vm.loading
    }
  }, [_vm._v(_vm._s(_vm.mode == 'login' ? 'Sign In' : 'Reset Password'))]), _c('v-btn', {
    staticClass: "mt-2",
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.toggleMode
    }
  }, [_vm._v(_vm._s(_vm.mode == 'login' ? 'Forgot Password' : 'Back to Sign-In'))])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }