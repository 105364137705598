var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.NAV_BAR_VISIBLE ? _c('div', [_c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "disable-route-watcher": "",
      "mobile-breakpoint": "md",
      "temporary": ['xs', 'sm', 'md'].includes(_vm.screenSize),
      "floating": ""
    },
    model: {
      value: _vm.open,
      callback: function callback($$v) {
        _vm.open = $$v;
      },
      expression: "open"
    }
  }, [_c('v-img', {
    staticClass: "ml-4 mr-16 mt-4 mb-4",
    attrs: {
      "src": require("@/assets/lasercam-orthotics.svg"),
      "contain": ""
    }
  }), _c('v-row', [_c('transition', {
    attrs: {
      "name": "transition-fade-up"
    }
  }, [_vm.showMenuTree ? _c('v-col', {
    staticClass: "pr-8",
    attrs: {
      "id": "content"
    }
  }, [_c('NavTree', {
    attrs: {
      "menu": _vm.menu
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }