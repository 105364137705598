<template>
  <div>
  <v-autocomplete
    clearable outlined dense hide-details :label='label'
    :search-input.sync='searchText' no-filter
    :items='items'
    :loading='loading'
    item-text='ref' item-value='xid'
    :value='value' @change='valueChanged'
    return-object
  />

</div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'JobSearch',

  components: {
  },

  props: {
    value: { type: String, default: null },
    label: { type: String, required: false, default: "Job" }
  },

  data: () => ({
    searchText: null,
    loading: false,
    items: [],
  }),

  computed: {
  },

  watch: {
    searchText(text){ this.search(text); },
    value() { this.search(this.value, false); }
  },

  methods:{
    ...mapActions("API", ["API"]),
    search(lookup, autosave){
      if(!lookup) return;
      autosave = autosave ?? 500;
      this.loading = true;
      this.API({ method: 'POST', endpoint: `lookup/job`, data: { lookup }, autosave })
      .then(items => { this.items = items; })
      .finally(() => { this.loading = false; });
    },

    valueChanged(xid){
      this.$emit("input", xid)
    }

  },

  mounted() {
    this.search(this.value, false);
  },

};
</script>
