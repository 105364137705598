var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    attrs: {
      "clearable": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": _vm.label,
      "search-input": _vm.searchText,
      "no-filter": "",
      "items": _vm.items,
      "loading": _vm.loading,
      "item-text": "ref",
      "item-value": "xid",
      "value": _vm.value,
      "return-object": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchText = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchText = $event;
      },
      "change": _vm.valueChanged
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }