<template>
  <div>
  <v-autocomplete
    clearable outlined dense hide-details label='Manufacture Step'
    no-filter
    :items='items'
    :loading='loading'
    item-text='title' item-value='xid'
    :value='value' @change='valueChanged'
  />

</div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'StepInput',

  components: {
  },

  props: {
    value: { type: String, default: null }
  },

  data: () => ({
    searchText: null,
    loading: false,
    items: [],
  }),

  computed: {
  },

  watch: {
  },

  methods:{
    ...mapActions("API", ["API"]),

    refreshSteps(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `qms/steps` })
      .then(items => { this.items = items; })
      .finally(() => { this.loading = false; });
    },

    valueChanged(xid){
      this.$emit("input", xid)
    }

  },

  mounted() {
    this.refreshSteps();
  },

};
</script>
