<template>
  <v-app-bar app :value="APP_BAR_VISIBLE" style='background-color: #F8F8F8;' elevate-on-scroll>
      <v-app-bar-nav-icon v-if='NAV_BAR_VISIBLE' class='primary--text hidden-lg-and-up' @click.stop="NAV_BAR_TOGGLE" />
      <span class='primary--text titlefont font-weight-medium' style='font-size:1.3em'>{{PAGE_TITLE}}</span>
      <v-spacer></v-spacer>
      <v-progress-circular indeterminate color="primary" v-if="API_BUSY" class='mr-5' size="22" width="3"/>

      <AccountSearch class='mr-5' />

      <v-btn fab plain @click='()=>$refs.qr.scan()' class='primary--text'><v-icon>mdi-qrcode-scan</v-icon></v-btn>
      <v-btn fab plain @click='TOGGLE_EDUCATION' class='primary--text'><v-icon>mdi-school</v-icon></v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{on,attrs}">
          <v-btn fab plain class='primary--text' v-bind='attrs' v-on='on'>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list style='mhin-width:180px'>
          <v-list-item @click='LOGOUT'>
            <v-icon class='primary--text mr-4'>mdi-logout</v-icon>Sign Out
          </v-list-item>
          <v-list-item @click='NAVIGATE("/settings")'>
            <v-icon class='primary--text mr-4'>mdi-cog</v-icon>Settings
          </v-list-item>
        </v-list>

      </v-menu>

      <BarcodeScannerPopup ref='qr' @scan='qrcode'/>
      <TaskAssigner ref='taskAssigner' rapid />

  </v-app-bar>
</template>


<style lang="scss" scoped>
@import "@/assets/common";

#background{
  @include wallpaper-expand();
  @include wallpaper2(1, false);
}
#content{
  position: absolute;
}

</style>


<script>
import { mapGetters, mapActions } from 'vuex'
import BarcodeScannerPopup from '@/components/BarcodeScannerPopup'
import TaskAssigner from '@/components/QMS/TaskAssigner'
import AccountSearch from '@/components/AccountSearch'
export default {
  name: 'AppBar',
  components: {
    BarcodeScannerPopup, TaskAssigner, AccountSearch
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('UI', ['APP_BAR_VISIBLE', 'NAV_BAR_VISIBLE', 'PAGE_TITLE']),
    ...mapGetters('API', ['API_BUSY']),
  },
  methods: {
    ...mapActions('UI', ['NAV_BAR_TOGGLE', 'TOGGLE_EDUCATION', 'NAVIGATE', 'ALERT']),
    ...mapActions('Auth', ['LOGOUT']),
    qrcode({text}){
      if(text.indexOf('LC_J:') === 0)
        this.NAVIGATE(`/job/${text.substr(5)}`);
      else if(text.indexOf('LC_S:') === 0)
        this.$refs.taskAssigner.open({ step: text.substr(5) });
      else
        this.ALERT({ title: 'Invalid Barcode', message: 'Please try again.' })
    }
  },
};
</script>
