var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "value": _vm.visible,
      "persistent": "",
      "scrollable": "",
      "width": "80vw",
      "max-width": "700px"
    }
  }, [_vm.visible ? _c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Task Assignment")]), _c('v-card-text', {
    staticClass: "pa-4",
    staticStyle: {
      "max-height": "80vh"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('BarcodeScanner', {
    on: {
      "scan": _vm.qr
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('DelegateInput', {
    staticClass: "mb-4",
    model: {
      value: _vm.delegate,
      callback: function callback($$v) {
        _vm.delegate = $$v;
      },
      expression: "delegate"
    }
  }), _c('StepInput', {
    staticClass: "mb-4",
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }), _c('JobSearch', {
    staticClass: "mb-4",
    model: {
      value: _vm.job,
      callback: function callback($$v) {
        _vm.job = $$v;
      },
      expression: "job"
    }
  }), _vm.rapid ? _c('v-sheet', {
    staticClass: "pa-2",
    staticStyle: {
      "height": "195px",
      "overflow-y": "auto"
    },
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('div', [_c('span', {
    staticClass: "primary--text font-weight-bold"
  }, [_vm._v("Jobs Assigned: " + _vm._s(_vm.rapidEntered.length))])]), _vm._l(_vm.rapidEntered, function (j, i) {
    return [_c('div', {
      key: i
    }, [_vm._v(_vm._s(j.jobRef || j.job))])];
  })], 2) : _vm._e()], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "disabled": _vm.rapid
    },
    on: {
      "click": _vm.clickAdd
    }
  }, [_vm._v(_vm._s(_vm.rapid ? "Rapid Entry" : "Add"))])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }