var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "dialogs"
    }
  }, [_c('v-dialog', {
    attrs: {
      "max-width": "550",
      "persistent": ""
    },
    model: {
      value: _vm.dialogActive,
      callback: function callback($$v) {
        _vm.dialogActive = $$v;
      },
      expression: "dialogActive"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-card-text', {
    staticClass: "pb-0 pt-10 text-subtitle-2"
  }, [_c('div', {
    staticClass: "d-block justify-center"
  }, [_c('v-img', {
    staticClass: "mx-auto mt-2",
    attrs: {
      "src": "prescribe-logo.svg",
      "width": "70%"
    }
  })], 1), _c('div', {
    staticClass: "my-8 text-center"
  }, [_c('p', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.message))]), _vm.retry ? _c('p', {
    staticClass: "text-caption secondary--text"
  }, [_vm._v("Please ensure "), _c('span', {
    staticClass: "font-weight-mediugm",
    staticStyle: {
      "color": "#2280C6"
    }
  }, [_vm._v("LaserCAM Prescribe")]), _vm._v(" is running to perform this action.")]) : _vm._e()])]), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#2280C6",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.clickRetry
    }
  }, [_vm._v("Retry")]), _vm.retry ? _c('v-btn', {
    attrs: {
      "color": "secondary darken-1",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.clickCancel
    }
  }, [_vm._v("Cancel")]) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }