import Vue from 'vue'
import Vuex from 'vuex'

import API from './API/API';
import Auth from './Auth';
import UI from './UI';
import PRESCRIBE from './Prescribe';
import PRINTING from './Printing';

Vue.use(Vuex)
let store = new Vuex.Store({
  modules: {
    API, Auth, UI, PRESCRIBE, PRINTING
  }
});
store.dispatch('API/init');
store.dispatch('UI/init');
store.dispatch('PRINTING/_init');

export default store;
