<template>
  <div></div>
</template>

<style lang="scss" scoped>
</style>

<script>
//import vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Deferred from '@/helpers/Deferred';

export default {
  name: 'InboxCounter',

  components: {
  },

  data: () => ({
    dialogActive: false,
    message: "",
    command: "",
    retry: false,
    loading: false,
  }),

  computed: {
    ...mapGetters("Auth", ['MODULES']),
  },

  watch: {
    MODULES(){ this.update() }
  },

  methods:{
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['SET_INBOX_COUNT']),

    update(){
      let deferred = new Deferred;
      if(!this.MODULES.map(m => m.code).includes('incoming')){
        deferred.resolve();
        return deferred.promise;
      }
      this.API({ method: 'GET', endpoint: 'files' })
      .then(data => {
        this.SET_INBOX_COUNT(data.length)
      })
      .finally(deferred.resolve)
      return deferred.promise;
    },

    updateLoop(){
      this.update()
      .finally(() => {
        setTimeout(() => {
          this.updateLoop();
        }, 60*1000); // 60 second updates
      })
    }

  },

  mounted() {
    this.updateLoop();
  },

};
</script>
