import axios from 'axios'
import Deferred from '@/helpers/Deferred';

//const TAG_WAIT_TIME = 3000;  //how many millisencons to wait for tagged requests

class ApiRequest{
  constructor(tag, method, endpoint, headers, data, waitTime, progress){
    this.tag = tag;             // tags are used to cancel a request if a new one is made with same tag
    this.method = method;           // POST, GET
    this.endpoint = endpoint;   // request path
    this.data = data;     // data to send if POST
    this.headers = headers;
    this.wait = Date.now();
    if(tag) this.wait += waitTime;
    this.progress = progress;
    this.status = 'queued';
    this.deferred = new Deferred();

  }

  ready(){
    return this.wait < Date.now() && this.status === 'queued';
  }

  fetch(){
    this.status = 'active';
    let http = axios({
      method: this.method,
      url: this.endpoint,
      data: this.data,
      responseType: 'json',
      headers: this.headers,
      onUploadProgress: this.progress,
    });
    return http;
  }

  retry(milliseconds){
    this.wait = Date.now() + milliseconds;
    this.status = 'queued';
  }

}

export default ApiRequest;
