const PRESCRIBE = {
  namespaced: true,
  state: {
    commandQueue: [],
  },
  getters: {
    COMMAND_QUEUE_LENGTH: state => state.commandQueue.length,
  },
  mutations: {
    queueCommand(state, command){
      state.commandQueue.push(command);
    },
    commandAdvanceQueue(state){
      state.commandQueue.splice(0,1);
    },
  },
  actions: {
    PRESCRIBE_COMMAND({ commit }, { message="", command="" }){
      commit('queueCommand', { message, command });
    },
    COMMAND_ADVANCE_QUEUE({ commit, state }){
      let queuedItem = state.commandQueue[0];
      commit('commandAdvanceQueue');
      return queuedItem;
    },
    init(){ }
  }
};

export default PRESCRIBE;
