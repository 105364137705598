<template>
  <div>
    <div class='navItem'
      :style='{ paddingLeft: offset }'
      :class='{ active: ($route.path == item.route) }'
      @click='click'
      >
      <div class='my-1 py-3 px-1 d-flex'>
        <div class="d-flex align-center">
          <v-icon class='mx-4 icon' size="1.2em">{{item.icon}}</v-icon>
          <div class="font-weight-medium">{{ itemText }}</div>
        </div>
        
      </div>
    </div>
    <div v-if="expandable">
      <v-expand-transition>
        <NavTree v-if="expand" :menu="item.children" :depth='depth+1' />
      </v-expand-transition>
    </div>

  </div>
</template>


<style lang="scss">
@import "@/assets/common";
$radius: 6px;

.navItem{
  background-color: white;
  border-radius: 0px $radius $radius 0px;
  cursor: pointer;
  color: $secondary;
  .icon{ color: $secondary; }
  &:hover{
    background-color: rgba($primary, 10%);
    color: $primary;
    .icon{ color: $primary; }
  }
  &.active{
    background-color: $primary;
    color: white;
    .icon{ color: white; }
    &:hover{
      background-color: rgba($primary, 0.85);
    }
  }
}

</style>


<script>
import { mapActions, mapGetters } from 'vuex'
const OFFSET_WIDTH = 25;
const WIDTH = 100;

export default {
  name: 'navitem',

  components: { NavTree: () => import('./NavTree') },

  props: {
    item: { type: Object },
    depth: { type: Number, default: 0 },
  },

  data: () => ({
    expand: false,
  }),

  computed: {
    ...mapGetters("UI", ['INBOX_COUNT']),
    width(){ return (WIDTH - (this.depth * OFFSET_WIDTH)) + 'px' },
    offset(){ return (this.depth * OFFSET_WIDTH) + 'px' },
    expandable(){ return this.item.children?.length > 0 },
    expandIcon(){
      if(this.expandable)
        return this.expand ? 'mdi-menu-up' : 'mdi-menu-down';
      return null;
    },
    itemText(){
      return this.item.name
      .replace("{inboxCount}", this.INBOX_COUNT === null ? "" : `(${this.INBOX_COUNT})`);
    }
  },

  watch: {

  },

  methods:{
    ...mapActions('UI', ['NAVIGATE']),

    click(){
      if(this.expandable){
        this.expand = !this.expand;
      }
      else{
        let route = this.item.route;
        if(!route) return;
        if(route == this.$route.path) return;
        this.NAVIGATE(this.item.route);
      }
    }
  },

  mounted(){

  }
}
</script>
