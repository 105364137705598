var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "navItem",
    class: {
      active: _vm.$route.path == _vm.item.route
    },
    style: {
      paddingLeft: _vm.offset
    },
    on: {
      "click": _vm.click
    }
  }, [_c('div', {
    staticClass: "my-1 py-3 px-1 d-flex"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "mx-4 icon",
    attrs: {
      "size": "1.2em"
    }
  }, [_vm._v(_vm._s(_vm.item.icon))]), _c('div', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.itemText))])], 1)])]), _vm.expandable ? _c('div', [_c('v-expand-transition', [_vm.expand ? _c('NavTree', {
    attrs: {
      "menu": _vm.item.children,
      "depth": _vm.depth + 1
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }