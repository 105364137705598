import router from '../router/index.js'
import Cookies from 'js-cookie'

const Auth = {
  namespaced: true,
  state: {
    authToken: JSON.parse(Cookies.get('LCAAT') || null), //lasercam api authentication token
    modules: JSON.parse(Cookies.get('LCAAM') || null) || [],
    user: JSON.parse(Cookies.get('LCC_AUTH_U') || null),
  },
  getters: {
    LOGGED_IN(state){ return state.authToken != null; },
    TOKEN(state){ return state.authToken; },
    MODULES(state){ return state.modules; },
    USER(state){ return state.user; },
    ACCOUNT(state){ return state.user?.account ?? null; }
  },
  mutations: {
    setToken(state, authToken){
      state.authToken = authToken;
      Cookies.set('LCAAT', JSON.stringify(authToken));
    },
    logout(state){
      state.authToken = null;
      Cookies.remove('LCAAT');
      Cookies.remove('LCAAM');
      Cookies.remove('LCC_AUTH_U');
      state.modules = [];
    },
    setModules(state, modules){
      state.modules = modules;
      Cookies.set('LCAAM', JSON.stringify(modules));
    },
    setUser(state, user){
      state.user = user;
      Cookies.set('LCC_AUTH_U', JSON.stringify(user));
    },
  },
  actions: {

    LOGIN({commit, dispatch}, { token, user }){
      commit('setToken', token);
      commit('setUser', user);
      dispatch('REFRESH_MODULES');
      dispatch('UI/CLEAR_NOTIFICATIONS', null, { root: true });
      dispatch('UI/NOTIFY', { message: "Signed in." }, { root: true });
    },

    LOGOUT({commit}){
      commit('logout');
      window.location = '/';
    },

    REQUIRE_LOGIN({getters, dispatch}, callback = null){
      if(getters.LOGGED_IN){
        if(callback) callback();
      }
      else{
        dispatch('UI/NOTIFY', { message: "Please sign in." }, { root: true });
        router.push('Login');
      }
    },

    REFRESH_MODULES({commit, dispatch, getters}){
      return new Promise((resolve) => {
        dispatch('API/API', { endpoint: 'core/auth/user_modules', data: {
          token: getters.TOKEN,
        }}, { root: true })
        .then((modules)=>{ commit('setModules', modules); })
        .catch(() => {})
        .finally(() => { resolve(); })
      });
    }

  }
};

export default Auth;
