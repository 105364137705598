<template>
  <div>
  <v-autocomplete
    clearable outlined dense hide-details label='Delegate'
    :items='items'
    :loading='loading'
    item-text='label' item-value='xid'
    :value='value' @change='valueChanged'
  />

</div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DelegateInput',

  components: {
  },

  props: {
    value: { type: String, default: null }
  },

  data: () => ({
    loading: false,
    items: [],
  }),

  computed: {
  },

  watch: {
  },

  methods:{
    ...mapActions("API", ["API"]),
    loadItems(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `qms/delegates` })
      .then(data => {
        this.items = data.delegates;
        this.value = data.current_user;
        this.valueChanged(data.current_user);
      })
      .finally(() => { this.loading = false; });
    },

    valueChanged(xid){
      this.$emit("input", xid)
    }

  },

  mounted() {
    this.loadItems();
  },

};
</script>
