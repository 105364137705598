<template>
  <v-app id='bg'>

    <app-bar />

    <nav-bar />

    <network-error-overlay :show="API_OFFLINE" />

    <v-main>
      <router-view @reload='reload' :key='reloadKey' />
    </v-main>

    <notification-snacks  />
    <dialogs />
    <prescribe-launcher />
    <inbox-counter />

  </v-app>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');
@import "@/assets/common";
#bg{
  background-color: #F8F8F8;
}
body{
  .v-application {
    //font-family: sans-serif !important;
    .titlefont{
      font-family: 'Rubik', sans-serif;
    }
 }
}
</style>


<script>
import { mapGetters } from 'vuex'
import NetworkErrorOverlay from '@/components/NetworkErrorOverlay'
import NotificationSnacks from '@/components/NotificationSnacks'
import AppBar from '@/components/AppBar'
import NavBar from '@/components/NavBar/NavBar'
import Dialogs from '@/components/Dialogs'
import PrescribeLauncher from '@/components/PrescribeLauncher'
import InboxCounter from '@/components/InboxCounter'

export default {
  name: 'App',

  components: {
    NetworkErrorOverlay,
    NotificationSnacks,
    NavBar,
    AppBar,
    Dialogs,
    PrescribeLauncher,
    InboxCounter,
  },

  data: () => ({
    //
    reloadKey: 0, // counter used as key to re-render router-view
  }),
  computed: {
    ...mapGetters('UI', ['APP_BAR_VISIBLE', 'NAV_BAR_VISIBLE', 'NOTIFICATIONS']),
    ...mapGetters('API', ['API_BUSY', 'API_OFFLINE']),
  },
  methods: {
    reload(){ this.reloadKey ++; } //force the router-view to re-render children
  },

};
</script>
