
import ApiRequest from './ApiRequest';

const TICK_TIME = 100; // interval to check queued requests (ms)
const NETWORK_RETRY_DELAY = 2000; // interval to retry after network fail (ms)

const API = {
  namespaced: true,
  state: {
    internetConnected: true,
    requests: [],
    api_base_address: window.app.config.api_base_url,
  },
  getters: {
    API_OFFLINE(state){
      return !state.internetConnected;
    },
    API_BUSY(state){
      return state.requests.length > 0;
    },
    API_BASE(state){ return state.api_base_address; },
  },
  mutations: {
    // add an api request to the queue (or replace by tag)
    _api_queue(state, { tag, method, endpoint, headers, data, callback, waitTime, progress }){
      //console.log("QUEUE");
      let request = new ApiRequest(tag, method, endpoint, headers, data, waitTime, progress);
      // look for a request with same tag queued to cancel
      let existingTag = -1;
      if(tag)
        existingTag = state.requests.findIndex(e => e.tag == tag && e.status == 'queued');
      // cancel tag if tagFound
      if(existingTag !== -1){
        //console.log("--request updated");
        state.requests.splice(existingTag, 1);
      }
      // add the new request
      state.requests.push(request);
      // flush queue if no tag
      if(tag == null || tag == undefined)
        state.requests.forEach(r => { r.wait = 0; });
      // return the request promise
      callback(request.deferred.promise);
    },

    //remove the first item from the queue
    _api_queue_progress(state){
      state.requests.shift();
    }

  },
  actions: {
    // entry point to make api calls
    API({commit, rootGetters, state}, {endpoint, data, autosave = false, method='POST', progress = null}){
      // return the request promise
      let promise = null;
      let callback = p => promise = p;
      let tag = autosave > 0 ? endpoint : null;
      let waitTime = autosave > 0 ? autosave : 0;
      let headers = { Authorization: `Bearer ${rootGetters['Auth/TOKEN']}` };
      endpoint = `${state.api_base_address}/${endpoint}`;
      commit('_api_queue', { endpoint, headers, data, callback, tag, method, waitTime, progress });
      return promise;
    },

    // take the next queued item and call it
    _api_call({state, dispatch, commit}){
      //check the item is ready
      if(state.requests.legnth == 0) return;
      let req = state.requests[0];
      if(!req.ready()) return;
      //trigger the http request
      req.fetch().then(response => {
        state.internetConnected = true;
        //resolve and progress queue
        if(response.data.success){
          req.deferred.resolve(response.data.data);
        }
        else{
          if(response.data.message)
            dispatch('UI/NOTIFY', { message: response.data.message }, { root: true });
          req.deferred.reject(response.data);
        }
        commit('_api_queue_progress');
      })
      .catch(e => {
        if(e.response){ // failed from bad http status
          state.internetConnected = true;
          //console.log("Http Error - Something went wrong", e.response);
          //didn't get a 2xx response, show generic error
          dispatch('UI/NOTIFY', { message: "Something went wrong." }, { root: true });
          //reject and progress queue
          req.deferred.reject();
          commit('_api_queue_progress');
        }
        else{
          // failed with no http status, network error? retry.
          state.internetConnected = false;
          req.retry(NETWORK_RETRY_DELAY);
        }
      });
    },

    // tick regularly checks if the top of the api queue is ready to send
    _tick({state, dispatch}){
      if(state.requests.length > 0){
        let r = state.requests[0];
        if(r.ready()){
          dispatch('_api_call');
        }
      }
      setTimeout( () => { dispatch('_tick'); }, TICK_TIME );
    },

    //startup
    init({dispatch}){
      dispatch('_tick');
    }
  }
};

export default API;
