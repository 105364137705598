import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);


export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00AD79',
        accent: '#39BD95',
        secondary:  '#5C5C5C',
        success: '#00AD79',
        warning: '#F0AD26',
        error: '#CC403D',
      },
      dark: {
        //primary: colors.blue.lighten3,
      },
    },
  },
  icons: {
    values: {
      //...CustomIcons,
      lasercam: { component: () => import('@/components/icons/lasercam') },
      bank: { component: () => import('@/components/icons/bank') },
      invoice: { component: () => import('@/components/icons/invoice') },
      home: { component: () => import('@/components/icons/home') },
      briefcase: { component: () => import('@/components/icons/briefcase') },
      customers: { component: () => import('@/components/icons/customers') },
      truck: { component: () => import('@/components/icons/truck') },
      'cloud-storage': { component: () => import('@/components/icons/cloud-storage') },
    }
  }
});


/*

*/
