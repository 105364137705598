var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "qrScanner"
  }, [_c('v-dialog', {
    attrs: {
      "value": _vm.open,
      "max-width": "350px",
      "persistent": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text text-body-1"
  }, [_vm._v("Scan QR Code")]), _c('v-card-text', {
    staticClass: "pa-6"
  }, [_vm.open ? _c('BarcodeScanner', {
    on: {
      "scan": _vm.onScan
    }
  }) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click($event) {
        _vm.open = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }