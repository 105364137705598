import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/login'
import Welcome from '../views/Welcome'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/password_reset/:token',
    name: 'PasswordReset',
    component: () => import( /* webpackChunkName: "password_reset" */ '../views/PasswordReset.vue')
  },
  {
    path: '/lab-home',
    component: () => import ( /* webpackChunkName "lab_home" */ '@/views/LabHome')
  },
  {
    path: '/incoming',
    name: 'Incoming',
    component: () => import( /* webpackChunkName: "customer_list" */ '../views/Incoming.vue')
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import( /* webpackChunkName: "customer_list" */ '../views/Customers.vue')
  },
  {
    path: '/customer/:customerId',
    name: 'Customer',
    component: () => import( /* webpackChunkName: "customer_details" */ '../views/Customer.vue')
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import( /* webpackChunkName: "job_list" */ '../views/Jobs.vue')
  },
  {
    path: '/job/:jobId',
    name: 'Job',
    component: () => import( /* webpackChunkName: "job_details" */ '../views/Job.vue')
  },
  {
    path: '/dispatch',
    name: 'Dispatch',
    component: () => import( /* webpackChunkName: "dispatch" */ '../views/Despatch/Despatch.vue')
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import( /* webpackChunkName: "invoices" */ '../views/Invoices/Invoices.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import( /* webpackChunkName: "reports" */ '../views/Reports/Reports.vue'),
    children: [
      {
        path: 'qms_completion',
        component: () => import ( /* webpackChunkName "settings_job_status" */ '@/views/Reports/QMS_Completion')
      },
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    redirect: '/settings/profile',
    component: () => import( /* webpackChunkName: "settings_page" */ '../views/Settings/Settings'),
    children: [
      {
        path: 'profile',
        component: () => import ( /* webpackChunkName "settings_job_status" */ '@/views/Settings/Profile')
      },
      {
        path: 'job_status',
        component: () => import ( /* webpackChunkName "settings_job_status" */ '@/views/Settings/JobStatus')
      },
      {
        path: 'jobs',
        component: () => import ( /* webpackChunkName "settings_job_status" */ '@/views/Settings/Jobs')
      },
      {
        path: 'permissions',
        component: () => import ( /* webpackChunkName "settings_permissions" */ '@/views/Settings/Permissions')
      },
      {
        path: 'users',
        component: () => import ( /* webpackChunkName "settings_users" */ '@/views/Settings/Users')
      },
      {
        path: 'accounts',
        component: () => import ( /* webpackChunkName "settings_accounts" */ '@/views/Settings/Accounts')
      },
      {
        path: 'tiers',
        component: () => import ( /* webpackChunkName "settings_tiers" */ '@/views/Settings/Tiers')
      },
      {
        path: 'products',
        component: () => import ( /* webpackChunkName "products" */ '@/views/Settings/Products')
      },
      {
        path: 'taxes',
        component: () => import ( /* webpackChunkName "products" */ '@/views/Settings/Taxes')
      },
      {
        path: 'prescribe',
        component: () => import ( /* webpackChunkName "prescribe" */ '@/views/Settings/Prescribe')
      },
      {
        path: 'qms',
        component: () => import ( /* webpackChunkName "settings_qms" */ '@/views/Settings/QMS')
      },
      {
        path: 'workflow',
        component: () => import ( /* webpackChunkName "settings_workflow" */ '@/views/Settings/Workflow')
      },
      {
        path: 'print',
        component: () => import ( /* webpackChunkName "settings_print" */ '@/views/Settings/Printers')
      },
      {
        path: 'dispatch',
        component: () => import ( /* webpackChunkName "settings_dispatch" */ '@/views/Settings/Dispatch')
      },
      {
        path: 'invoice',
        component: () => import ( /* webpackChunkName "settings_invoice" */ '@/views/Settings/Invoice')
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
