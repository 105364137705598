var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticStyle: {
      "background-color": "#F8F8F8"
    },
    attrs: {
      "app": "",
      "value": _vm.APP_BAR_VISIBLE,
      "elevate-on-scroll": ""
    }
  }, [_vm.NAV_BAR_VISIBLE ? _c('v-app-bar-nav-icon', {
    staticClass: "primary--text hidden-lg-and-up",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.NAV_BAR_TOGGLE($event);
      }
    }
  }) : _vm._e(), _c('span', {
    staticClass: "primary--text titlefont font-weight-medium",
    staticStyle: {
      "font-size": "1.3em"
    }
  }, [_vm._v(_vm._s(_vm.PAGE_TITLE))]), _c('v-spacer'), _vm.API_BUSY ? _c('v-progress-circular', {
    staticClass: "mr-5",
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "22",
      "width": "3"
    }
  }) : _vm._e(), _c('AccountSearch', {
    staticClass: "mr-5"
  }), _c('v-btn', {
    staticClass: "primary--text",
    attrs: {
      "fab": "",
      "plain": ""
    },
    on: {
      "click": function click() {
        return _vm.$refs.qr.scan();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-qrcode-scan")])], 1), _c('v-btn', {
    staticClass: "primary--text",
    attrs: {
      "fab": "",
      "plain": ""
    },
    on: {
      "click": _vm.TOGGLE_EDUCATION
    }
  }, [_c('v-icon', [_vm._v("mdi-school")])], 1), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "plain": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-account")])], 1)];
      }
    }])
  }, [_c('v-list', {
    staticStyle: {
      "mhin-width": "180px"
    }
  }, [_c('v-list-item', {
    on: {
      "click": _vm.LOGOUT
    }
  }, [_c('v-icon', {
    staticClass: "primary--text mr-4"
  }, [_vm._v("mdi-logout")]), _vm._v("Sign Out ")], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.NAVIGATE("/settings");
      }
    }
  }, [_c('v-icon', {
    staticClass: "primary--text mr-4"
  }, [_vm._v("mdi-cog")]), _vm._v("Settings ")], 1)], 1)], 1), _c('BarcodeScannerPopup', {
    ref: "qr",
    on: {
      "scan": _vm.qrcode
    }
  }), _c('TaskAssigner', {
    ref: "taskAssigner",
    attrs: {
      "rapid": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }