<template>
  <div>

    <v-dialog :value='visible' persistent scrollable width='80vw' max-width='700px'>
      <v-card v-if='visible'>
        <v-card-title class='primary white--text'>Task Assignment</v-card-title>
        <v-card-text style='max-height: 80vh;' class='pa-4'>
          <v-row>
            <v-col cols=6><BarcodeScanner @scan='qr' /></v-col>
            <v-col cols=6>
              <DelegateInput v-model='delegate' class='mb-4'/>
              <StepInput v-model='step' class='mb-4' />
              <JobSearch v-model='job' class='mb-4' />
              <v-sheet v-if='rapid' style='height:195px;overflow-y:auto;' outlined rounded class='pa-2'>
                <div>
                  <span class='primary--text font-weight-bold'>Jobs Assigned: {{rapidEntered.length}}</span>
                </div>
                <template v-for='j,i in rapidEntered'>
                  <div :key='i'>{{j.jobRef || j.job}}</div>
                </template>
              </v-sheet>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn class='secondary' @click='close'>Close</v-btn>
          <v-btn class='primary' @click='clickAdd' :disabled='rapid'>{{rapid?"Rapid Entry":"Add"}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import BarcodeScanner from '@/components/BarcodeScanner'
import JobSearch from '@/components/JobSearch'
import StepInput from './StepInput'
import DelegateInput from './DelegateInput'
export default {
  name: '',

  components: {
    BarcodeScanner, JobSearch, StepInput, DelegateInput
  },

  props: {
    rapid: { type: Boolean, default: false }
  },

  data: () => ({
    visible: false,
    job: null,
    step: null,
    delegate: null,
    rapidEntered: [],
  }),

  computed: {
  },

  methods:{
    ...mapActions("UI", ["NOTIFY"]),
    ...mapActions("API", ["API"]),
    open({ job, step }){
      this.job = job;
      this.step = step;
      this.visible = true;
      this.rapidEntered = [];
    },
    close(){ this.visible = false; },

    qr({text}){
      if(text.substr(0, 5) == 'LC_J:')
        this.job=text.substr(5);
      if(text.substr(0, 5) == 'LC_S:')
        this.step=text.substr(5);
      this.checkRapidEntry();
    },

    changed(e){
      console.log("CHANGED", e);
    },

    assignTask(){
      let data = { job: this.job, task: this.step, delegate: this.delegate };
      return this.API({ method: 'POST', endpoint: 'qms/activity', data })
    },

    clickAdd(){
      this.assignTask()
      .then(() => {
        this.$emit('taskAdded');
        if(this.rapid) this.job = null;
        else this.close();
      });
    },

    checkRapidEntry(){
      if(!this.rapid) return;
      if(!this.job || !this.step || !this.delegate) return;
      if(this.rapidEntered.find(j => j.job == this.job)){
        //this.NOTIFY({ message: 'Job already entered' });
        //return;
      }
      this.rapidEntered.unshift({ job: this.job, jobRef: null });
      this.API({ method: 'POST', endpoint: 'lookup/job', data: { lookup: this.job } })
      .then(results => {
        let job = results[0];
        if(!job) return;
        console.log("LOOKUP:", job)
        this.rapidEntered.forEach(re => {
          if(re.job === job.xid) re.jobRef = job.ref;
        });
      });
      this.clickAdd();
    }

  },

  mounted() {
    this.job = this.jobId;
  },

};
</script>
