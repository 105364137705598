var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-overlay', {
    staticClass: "pa-8 text-center",
    attrs: {
      "value": _vm.show,
      "opacity": "0.85"
    }
  }, [_c('transition', {
    attrs: {
      "name": "transition-fade-up"
    }
  }, [_vm.showInner ? _c('v-row', [_c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "12",
      "sm": "10",
      "md": "12",
      "lg": "10",
      "xl": "8"
    }
  }, [_c('v-card', {
    staticClass: "rounded-xl px-8",
    attrs: {
      "elevation": "20"
    }
  }, [_c('v-card-title', {
    staticClass: "pb-2 pt-8"
  }, [_c('p', {
    staticClass: "warning--text text-h4 mx-auto",
    staticStyle: {
      "word-break": "normal"
    }
  }, [_vm._v(" Please check your connection."), _c('br'), _c('v-progress-circular', {
    staticClass: "mt-5",
    attrs: {
      "indeterminate": "",
      "color": "warning"
    }
  })], 1)]), _c('v-card-text', [_c('p', [_vm._v("If the problem persists, please refer to our guide."), _c('br'), _c('a', [_vm._v("Resolving connection issues on LaserCAM Cloud")])])])], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }