var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.menu, function (item, i) {
    return _c('div', {
      key: i
    }, [_c('div', [_c('NavItem', {
      attrs: {
        "item": item,
        "depth": _vm.depth
      }
    })], 1)]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }