<template>
  <div>
    <div v-for="(item, i) in menu" v-bind:key="i">
      <div>
        <NavItem :item="item" :depth='depth' />
      </div>
    </div>
  </div>
</template>


<style lang="scss">
@import "@/assets/common";

</style>


<script>
import NavItem from './NavItem'

export default {
  name: 'NavTree',

  components: { NavItem },

  props: {
    menu: { type: Array },
    depth: {type: Number, default: 0 }
  },

  data: () => ({
  }),

  computed: {

  },

  watch: {

  },

  methods:{

  },

  mounted(){
  }
}
</script>
