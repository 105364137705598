import Deferred from '@/helpers/Deferred'
const QZ = window.qz;

const PRINTING = {
  namespaced: true,
  state: {
    connected: false,
    printers: [],
    configs: {},
  },
  getters: {
    RAW_PRINTERS: state => state.printers,
    PRINTERS: state => state.printers.map(p => p.name),
    CONNECTED: state => state.connected,
  },
  mutations: {
    PRINTERS(state, value){ state.printers = value; },
    QZ_CONNECTED(state, value){ state.connected = value; },
    SET_PRINTER_CONFIGS(state, configs){
      state.configs = configs;
    },
  },
  actions: {
    _init({ dispatch, commit }){
      QZ.websocket.setClosedCallbacks(() => {
        commit('QZ_CONNECTED', false);
        commit('PRINTERS', []);
      });
      dispatch('_configureSecurity');
      dispatch('_connect');
      dispatch('REFRESH_PRINT_CONFIG');
    },

    _connect({ dispatch, commit }){
        // Disconnect
        commit('QZ_CONNECTED', null);
        let d = new Deferred;
        d.resolve();
        let disconnected = QZ.websocket.isActive() ? QZ.websocket.disconnect() : d.promise;

        disconnected.then(() => {
          commit('QZ_CONNECTED', null);
          QZ.websocket.connect({ host: 'localhost', port: { insecure: [], secure: [8181] } })
          .then(() => {
            commit('QZ_CONNECTED', true);
            dispatch('_refresh_printers');
          })
          .catch(() => { commit('QZ_CONNECTED', false); });
        })
    },

    _configureSecurity({ dispatch }){
      let API = req => dispatch('API/API', req, { root: true });
      // configure public certificate
      QZ.security.setCertificatePromise((resolve, reject) => {
        API({ method: 'GET', endpoint: 'qz/public' })
        .then(resolve).catch(reject);
      })
      // confiset signig algorithm
      QZ.security.setSignatureAlgorithm("SHA512"); // Since 2.1
      // configure private key signing
      QZ.security.setSignaturePromise(toSign => (resolve, reject) => {
        API({ method: 'POST', endpoint: 'qz/sign_command', data : { toSign } })
        .then(resolve).catch(reject);
      });
    },

    _refresh_printers({ commit }){
      commit('PRINTERS', []);
      QZ.printers.details()
      .then(printers => {
        commit('PRINTERS', printers);
      });
    },

    REFRESH_PRINT_CONFIG({ dispatch, commit }){
      let API = req => dispatch('API/API', req, { root: true });
      API({ method: 'GET', endpoint: 'account/printer_settings' })
      .then(settings => {
        commit('SET_PRINTER_CONFIGS', settings);
      });
      commit;
    },

    REFRESH({ dispatch }){ dispatch('_connect'); },

    async PRINT({ state, dispatch, getters }, { data, config, type, url }){
      // default options
      let options = { };
      // load config from loaded configurations
      state.configs.forEach(conf => {
        if(conf.type == type) options = { ...options, ...conf };
      });
      //overridden options
      options = { ...options, ...config };
      //preview if selected printer is not available
      let printer_name = options?.printer ?? null;
      if(!getters.PRINTERS.includes(printer_name)){
        if(url) window.open(url, "_blank");
        else dispatch("PREVIEW", data);
        return;
      }
      // create QZ config from print options
      let qz_conf = QZ.configs.create(printer_name, {
        units: 'mm', scaleContent: true,
        size: { width: options.paper_width, height: options.paper_height },
        margins: {
          left: options.margin_left, right: options.margin_right,
          top: options.margin_bottom, bottom: options.margin_bottom,
        },
        jobName: options.job_name ?? null,
        colorType: (options.colour ?? false) ? 'color' : 'grayscale',
        copies: options.copies ?? null,
        duplex: (options.duplex ?? false) ? true : false,
      });
      // load url data to base64 (because QZ requires a url to include ".pdf")
      if(url){
        data = await window.fetch(url);
        data = await data.blob();
        let { promise, resolve } = new Deferred();
        let reader = new FileReader();
        reader.onload = () => { resolve(reader.result.split(',')[1]); }
        reader.readAsDataURL(data);
        data = await promise;
      }
      // send to printer
      QZ.print(qz_conf, [{
        data, type: 'pixel', format: 'pdf', flavor: 'base64'
      }]);
    },

    PREVIEW($store, base64_data){
      base64_data = Uint8Array.from(atob(base64_data), c => c.charCodeAt(0))
      // decode base64 data into a pdf blob
      let pdf_blob = new Blob([base64_data], { type : 'application/pdf' });
      // open blob in new window from a temporary URL
      let blob_url = URL.createObjectURL(pdf_blob, "_blank");
      window.open(blob_url, "_blank");
      URL.revokeObjectURL(blob_url); // revoke to release memory
    },

    SET_PRINTER_CONFIG({ commit, dispatch }, { type, config }){
      let API = req => dispatch('API/API', req, { root: true });
      API({ method: 'POST', endpoint: 'account/printer_settings', data : { type, ...config } })
      .then(configs => {
        commit('SET_PRINTER_CONFIGS', configs);
      });
    },

    GET_PRINTER_CONFIG({ state }, type){
      return state.configs.find(conf => conf.type == type) ?? {};
    },


  }

};

export default PRINTING;
