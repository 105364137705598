var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('v-combobox', {
    attrs: {
      "small": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "return-object": "",
      "no-filter": "",
      "placeholder": "Find...",
      "prepend-inner-icon": "mdi-magnify",
      "items": _vm.results
    },
    on: {
      "update:search-input": _vm.search,
      "change": _vm.clickItem
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.type == 'Customer' ? [_c('v-icon', {
          staticClass: "primary--text mr-4"
        }, [_vm._v("$customers")]), _vm._v(" " + _vm._s(item.data.name) + " ")] : _vm._e(), item.type == 'User' ? [_c('v-icon', {
          staticClass: "primary--text mr-4"
        }, [_vm._v("$customers")]), _c('v-lists-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.data.user.name_first) + " " + _vm._s(item.data.user.name_last))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.data.customer.name))])], 1)] : _vm._e(), item.type == 'Job' ? [_c('v-icon', {
          staticClass: "primary--text mr-4"
        }, [_vm._v("$briefcase")]), _c('v-lists-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.data.description))]), item.data.ship_to ? _c('v-list-item-subtitle', [_vm._v(_vm._s(item.data.ship_to.company_name))]) : item.data.customer ? _c('v-list-item-subtitle', [_vm._v(_vm._s(item.data.customer.name))]) : _vm._e()], 1)] : _vm._e(), item.type == 'Location' ? [_c('v-icon', {
          staticClass: "primary--text mr-4"
        }, [_vm._v("$customers")]), _c('v-lists-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.data.company_name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.data.customer.name))])], 1)] : _vm._e()];
      }
    }]),
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }