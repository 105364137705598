<template>
  <v-container fill-height fluid>
    <div id='wallpaper' />
    <transition name='transition-fade-up'>
      <v-row v-if='showForm'>
        <v-col cols=11 sm=8 md=11 lg=10 xl=6 class='mx-auto'>
          <v-card elevation=20 class='rounded-xl' style='overflow:hidden;'>
            <v-row class='text-center d-flex'>

              

              <v-col cols=12 md=6 class='pa-0 hidden-sm-and-down' id='rightPane'>
                <div id='wallpaper1'></div>
                <div id='wallpaper2'></div>
                <v-container fill-height>
                  <v-container class='text-center pa-10 titlefont' id="text">
                    <p class='ma-0'>Your gateway to prescribing, manufacturing and dispensing industry leading orthoses.</p>
                    <p class='ma-0 text-subtitle-1'></p>
                  </v-container>
                </v-container>
              </v-col>

              <v-col cols=12 md=6 class='pa-8 pa-lg-16'>
                <v-container fill-height>
                  <v-container class='text-center'>
                    <v-img src="@/assets/logo.svg" class='mx-auto' style='width:95%;' />
                    <p class='text-body-2 text--secondary my-10' v-if="mode=='login'">Sign in to get started!</p>
                    <p class='text-body-2 text--secondary my-10' v-else>Let's reset that for you!</p>
                    <v-form @submit.prevent="submit" :disabled='loading'>
                      <v-text-field v-model='username' label='Username' outlined rounded color='primary' autofocus />
                      <v-text-field v-if="mode=='login'" ref='password' v-model='password' label='Password' outlined rounded color='primary' type='password' />
                      <v-btn type='submit' rounded large block class='primary white--text' :loading='loading'>{{mode=='login'?'Sign In':'Reset Password'}}</v-btn>
                      <v-btn class="mt-2" x-small text @click="toggleMode">{{mode=='login'?'Forgot Password':'Back to Sign-In'}}</v-btn>
                    </v-form>
                  </v-container>
                </v-container>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/assets/common";

#rightPane{
  position: relative;

  #wallpaper1{
    @include wallpaper2(1, $primary);
  }

  #wallpaper2{
    @include wallpaper-expand();
    background-image: linear-gradient(to bottom right, $primary, darken($primary,20%));
    opacity:0.7;
  }

  #text{
    color: white;
    font-weight: lighter;
    opacity: 1;
    font-size: 1.9em;
    z-index: 0;
    text-shadow: 0px 0px rgba(black, 0.1);
  }
}

</style>


<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Login',

  components: {
  },
  data: () => ({
    showForm: false,
    username: '',
    password: '',
    otp: null,
    loading: false,
    mode: 'login',
  }),
  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),
  },
  methods:{
    ...mapActions('UI', ['HIDE_NAVBARS', 'PROMPT', 'ALERT']),
    ...mapActions('Auth', ['LOGIN']),
    ...mapActions('API', ['API']),
    ...mapActions('PRINTING', ['REFRESH_PRINT_CONFIG']),

    submit_login(){
      //console.log(this.username, this.password);
      this.loading = true;
      this.API({ endpoint: 'core/auth/login', data: {
        username: this.username,
        password: this.password,
        otp: this.otp,
      }}).then(data => {
        this.LOGIN(data);
        this.REFRESH_PRINT_CONFIG();
        this.loading = false;
        this.showForm = false;
        setTimeout(()=>{ this.$router.push('/'); }, 700);
      }).catch(e => {
        this.loading = false;
        if(e.statusCode == 'OTP') this.OTP_Required();
        else{
          if(this.otp === null) this.password = '';
          this.otp = null;
          Vue.nextTick(()=> { this.$refs['password'].focus(); });
        }
      });
    },

    submit(){
      if(this.mode == 'login') this.submit_login();
      if(this.mode == 'reset') this.submit_reset();
    },

    submit_reset(){
      let data = { username: this.username };
      this.API({ method: 'POST', endpoint: 'core/auth/request_password_reset', data })
      .then(() => {
        this.ALERT({ title: 'Password Reset', message: 'Password reset instructions have been sent to your account email.' });
        this.mode = 'login';
      });
    },

    async OTP_Required(){
      this.otp = await this.PROMPT({ title: "Two Factor Authentication", message: "Please enter your 2FA code:" });
      this.submit();
    },

    toggleMode(){
      this.mode = this.mode == 'login' ? 'reset' : 'login';
    }

  },
  mounted(){
    this.HIDE_NAVBARS();
    if(this.LOGGED_IN){
      this.$router.push('/');
    }
    this.showForm = true;

  }
};
</script>
