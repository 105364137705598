<template>
  <div v-if='NAV_BAR_VISIBLE'>
    <v-navigation-drawer app disable-route-watcher v-model='open' mobile-breakpoint='md'
        :temporary="['xs', 'sm', 'md'].includes(screenSize)"
        floating
        class=''
        >
          <v-img src='@/assets/lasercam-orthotics.svg' contain class='ml-4 mr-16 mt-4 mb-4' />

          <v-row>
            <transition name='transition-fade-up'>
              <v-col v-if='showMenuTree' id='content' class='pr-8'>
                <NavTree :menu='menu' />
              </v-col>
            </transition>
          </v-row>


    </v-navigation-drawer>
  </div>
</template>


<style lang="scss">
@import "@/assets/common";

.v-treeview-node__root{
  color: $secondary;
  border-radius: 0px 15px 15px 0px;
  &:hover{
    color: $primary;
    background-color: rgba($primary, 0.08);
    .v-treeview-node__label, .v-icon{ color: $primary; }
  }
  &.NavBarActiveItem.primary--text{
    background-color: $primary;
    .v-treeview-node__content, .v-icon{ color: white; }
    &:hover{
      .v-treeview-node__label, .v-icon{ color: white; }
    }
  }
}

</style>


<script>
import vue from 'vue'
import { mapGetters } from 'vuex'

import Menu from './Menu'
import NavTree from './NavTree'

export default {
  name: 'NavBar',

  components: { NavTree },

  data: () => ({
    showMenuTree: false,
    open: false,
    menu: [],
    debounceNavigate: false,
  }),

  computed: {
    ...mapGetters('UI', ['NAV_BAR_VISIBLE', 'NAV_BAR_TOGGLED']),
    ...mapGetters('Auth', ['MODULES']),

    moduleCodes(){
      let codes = this.MODULES ? this.MODULES.map(m => m.code) : [];
      return codes;
    },

    screenSize(){
      return this.$vuetify.breakpoint.name;
    },

    allMenuItems(){
      let allItems = [];
      let addItems = (items) => {
        items.forEach(item => {
          addItems(item.children ?? []);
          allItems.push(item);
        });
      }
      addItems(this.menu);
      return allItems;
    },

  },

  watch: {
    NAV_BAR_VISIBLE(visible){
      this.showMenuTree = !visible;
      vue.nextTick(()=>{ this.showMenuTree = visible; });
    },
    NAV_BAR_TOGGLED(){
      this.open = !this.open;
    },
    screenSize(size){
      this.open = ['lg', 'xl'].includes(size);
    },
    MODULES(){
      this.updateMenuItems();
    },
  },

  methods:{
    updateMenuItems(){
      //create a menu object which only contains items with permission
      let returnMenu = JSON.parse(JSON.stringify(Menu));
      //filter function
      let itemId = 0;
      let filter = (items) => {
        //recurse filter child list
        items.forEach(item => {
          item.children = filter(item.children ?? []);
        });
        //filter current list
        items = items.filter(m => (
          m.children.length > 0 ? true :
          (this.moduleCodes.includes(m.moduleCode) || m.moduleCode === null)
        ));
        //add id's to each item
        items.forEach(item => { item.id = itemId++; });
        return items;
      };
      //return a deep filtered list
      this.menu = filter(returnMenu, this.moduleCodes, filter);
    },

  },

  mounted(){
    this.open = ['lg', 'xl'].includes(this.screenSize);
    vue.nextTick(()=>{ this.showMenuTree = true; });
    this.updateMenuItems();
  }
}
</script>
