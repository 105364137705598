<template>
  <div id="dialogs">

    <v-dialog v-model="dialogActive" max-width="550" persistent>
      <v-card elevation="20">
        <v-card-text class='pb-0 pt-10 text-subtitle-2'>
          <div class='d-block justify-center'>
            <v-img src='prescribe-logo.svg' width='70%' class='mx-auto mt-2' />
          </div>
          <div class='my-8 text-center'>
            <p class='text-body-1 font-weight-medium'>{{message}}</p>
            <p class='text-caption secondary--text' v-if='retry'>Please ensure <span class='font-weight-mediugm' style='color:#2280C6;'>LaserCAM Prescribe</span> is running to perform this action.</p>
          </div>
        </v-card-text>
        <v-card-actions class='pt-0'>
          <v-spacer></v-spacer>
          <v-btn color="#2280C6" class='white--text' @click='clickRetry' :loading='loading'>Retry</v-btn>
          <v-btn color="secondary darken-1" @click='clickCancel' v-if='retry' :loading='loading'>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/common";
#dialogs{

}
</style>

<script>
//import vue from 'vue'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PrescribeLauncher',

  components: {
  },

  data: () => ({
    dialogActive: false,
    message: "",
    command: "",
    retry: false,
    loading: false,
  }),

  computed: {
    ...mapGetters("PRESCRIBE", ['COMMAND_QUEUE_LENGTH']),
  },

  watch: {
    COMMAND_QUEUE_LENGTH(){ this.fetchNextCommand(); },
    dialogActive(){ this.fetchNextCommand(); },
  },

  methods:{
    ...mapActions("PRESCRIBE", ['COMMAND_ADVANCE_QUEUE']),

    checkCommandQueue(){

    },

    fetchNextCommand(){
      if(this.dialogActive || !this.COMMAND_QUEUE_LENGTH) return;
      this.dialogActive = true;
      this.COMMAND_ADVANCE_QUEUE()
      .then(({ command, message }) => {
        this.message = message;
        this.command = command;
        this.retry = false;
        this.loading = true;
        setTimeout(this.retryCommand, 800);
      });
    },

    clickRetry(){
      this.retry = false;
      this.retryCommand();
    },

    clickCancel(){
      if(this.dialogActive){ //debounce double click
        this.dialogActive = false;
      }
    },

    retryCommand(){
      this.loading = true;
      axios({
        method: 'GET',
        url: `http://localhost:46838/${this.command}`
      })
      .then(() => { this.dialogActive = false; })
      .catch(() => { this.retry = true; })
      .finally(() => { this.loading = false; });
    }

  },

  mounted() {
  },

};
</script>
