var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "qrScanner"
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    ref: "stage",
    staticClass: "stage"
  }, [_c('video', {
    ref: "feed"
  }), _c('v-img', {
    staticClass: "overlay",
    class: {
      flash: _vm.flash
    },
    attrs: {
      "src": "qr-window.png"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "label": "Devices",
      "items": _vm.devices,
      "item-text": "label",
      "item-value": "deviceId"
    },
    on: {
      "change": _vm.selectDevice
    },
    model: {
      value: _vm.device,
      callback: function callback($$v) {
        _vm.device = $$v;
      },
      expression: "device"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }