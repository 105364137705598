<template>
  <div class='qrScanner'>


    <v-dialog :value='open' max-width='350px' persistent>
      <v-card>
        <v-card-title class='primary white--text text-body-1'>Scan QR Code</v-card-title>
        <v-card-text class='pa-6'>
          <BarcodeScanner @scan="onScan" v-if='open' />
        </v-card-text>
        <v-divider />
        <v-card-actions><v-spacer></v-spacer><v-btn class='primary' @click='open=false'>Cancel</v-btn></v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
</style>

<script>
import BarcodeScanner from './BarcodeScanner'
export default {
  name: 'BarcodeScannerPopup',

  components: {
    BarcodeScanner
  },

  data: () => ({
    open: false,
  }),

  computed: {
  },

  watch: {

  },

  methods:{
    scan(){
      this.open = true;
    },
    onScan(code){
      this.$emit('scan', code);
      this.open = false;
    }
  },

  mounted() {
  },

};
</script>
