<template>
  <v-overlay :value="show" opacity=0.85 class='pa-8 text-center'>
    <transition name='transition-fade-up'>
      <v-row v-if='showInner'>
        <v-col cols=12 sm=10 md=12 lg=10 xl=8 class='mx-auto'>
          <v-card elevation=20 class='rounded-xl px-8'>
            <v-card-title class='pb-2 pt-8'>
              <p class='warning--text text-h4 mx-auto' style='word-break:normal;'>
                Please check your connection.<br />
                <v-progress-circular indeterminate color="warning" class='mt-5' />
              </p>
            </v-card-title>
            <v-card-text>
                <p>If the problem persists, please refer to our guide.<br><a>Resolving connection issues on LaserCAM Cloud</a></p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </transition>
  </v-overlay>
</template>

<style lang="scss" scoped>
@import "@/assets/common";
</style>


<script>

export default {
  name: 'NetworkErrorOverlay',
  props: {
    show: { type: Boolean, required: false, default: true },
  },
  data: () => ({
    showInner: null,
  }),
  watch: {
    show(value){
      setTimeout( () => { this.showInner = value; }, 100);
    }
  },
  mounted(){
    this.showInner = this.show;
  }
};
</script>
