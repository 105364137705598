<template>
  <v-container xv-if='LOGGED_IN && false'>

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//import Qz from '@/components/Qz'

export default {
  name: 'Welcome',

  components: {
    //Qz
  },

  data: () => ({
    counter: 0,
  }),
  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'MODULES']),
    auth_module_codes(){ return this.MODULES.map(m=>m.code); },
  },
  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'CONFIRM', 'NAVIGATE']),
    ...mapActions('Auth', ['REQUIRE_LOGIN', 'LOGOUT']),
    ...mapActions('API', ['API']),

    redirect(){
      if(this.auth_module_codes.includes("home_lab"))
        this.NAVIGATE('lab-home');
    }

  },
  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.SET_PAGE_TITLE('Welcome');
      this.redirect();
    });
  }
};
</script>
