<template>
  <div class="">

    <v-combobox
      small dense outlined hide-details return-object no-filter
      placeholder="Find..."
      prepend-inner-icon="mdi-magnify"
      v-model="searchText"
      @update:search-input="search"
      @change="clickItem"
      :items="results"
    >
      <template #item="{item}">

        <template v-if="item.type == 'Customer'">
          <v-icon class="primary--text mr-4">$customers</v-icon> {{ item.data.name }}
        </template>

        <template v-if="item.type == 'User'">
            <v-icon class="primary--text mr-4">$customers</v-icon>
            <v-lists-item-content>
              <v-list-item-title>{{ item.data.user.name_first }} {{ item.data.user.name_last }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.data.customer.name }}</v-list-item-subtitle>
            </v-lists-item-content>
        </template>

        <template v-if="item.type == 'Job'">
            <v-icon class="primary--text mr-4">$briefcase</v-icon>
            <v-lists-item-content>
              <v-list-item-title>{{ item.data.description }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.data.ship_to">{{ item.data.ship_to.company_name }}</v-list-item-subtitle>
              <v-list-item-subtitle v-else-if="item.data.customer">{{ item.data.customer.name }}</v-list-item-subtitle>
            </v-lists-item-content>
        </template>

        <template v-if="item.type == 'Location'">
            <v-icon class="primary--text mr-4">$customers</v-icon>
            <v-lists-item-content>
              <v-list-item-title>{{ item.data.company_name }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.data.customer.name }}</v-list-item-subtitle>
            </v-lists-item-content>
        </template>

      </template>
    </v-combobox>
    
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'AccountSearch',

  data: () => ({
    searchText: "",
    results: [],
  }),

  methods:{
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["NAVIGATE"]),

    search(search){
      // skip search and clear results if no search input
      if(search === null || search === "" || typeof search == 'object'){
        this.results = [];
        return;
      }
      this.API({ method: 'POST', endpoint: 'account/search', data: { search }, autosave: 750 })
      .then(results => { this.results = results; })
    },

    clickItem(item){
      this.$nextTick(() => { this.searchText = ""; });
      if(item.type == "Customer") this.NAVIGATE(`/customer/${item.data.xid}`);
      if(item.type == "Job") this.NAVIGATE(`/job/${item.data.xid}`);
      if(item.type == "Location") this.NAVIGATE(`/customer/${item.data.customer.xid}`);
      if(item.type == "User") this.NAVIGATE(`/customer/${item.data.customer.xid}`);
    }

  }

};
</script>
